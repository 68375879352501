import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { rem, transparentize } from 'polished';
import { RoomStates } from '../types';
import Logo from '../../assets/svg/logo-full.svg';

const copy = {
    [RoomStates.LoadingRoom]: 'Spinning up the room 🚀',
    [RoomStates.Loading]: 'Loading',
    [RoomStates.Connecting]: 'Connecting to room',
    [RoomStates.VideoLoading]: 'Loading video',
    [RoomStates.VideoPending]: 'Nothing is currently streaming',
    [RoomStates.SubscribeError]: 'Unable to load stream',
    [RoomStates.RoomNotFound]: 'Room not found',
    [RoomStates.OpenInBrowser]: 'Open in browser to join as a guest',
    [RoomStates.Disconnected]: 'Looks like you got disconnected. Refresh to rejoin.',
    [RoomStates.Queued]: 'Waiting to join',
    [RoomStates.Error]:
        'Looks like something’s not working quite right. Hopefully a quick reload will fix it.',
};

const loadingStates = [
    RoomStates.Loading,
    RoomStates.LoadingRoom,
    RoomStates.Connecting,
    RoomStates.VideoLoading,
];

interface RoomState {
    className?: string;
    state?: RoomStates;
    mini?: boolean;
    logo?: boolean;
    loading?: boolean;
    mobile?: boolean;
    onClick?: () => void;
}

const RoomState: React.FunctionComponent<RoomState> = (props) => {
    const {
        className,
        state,
        mini = false,
        logo = false,
        children = '',
        mobile = false,
        loading = false,
        onClick,
    } = props;

    const showLogo =
        logo ||
        [RoomStates.RoomNotFound, RoomStates.Disconnected, RoomStates.Error].includes(state);

    return (
        <RoomStateStyled className={className}>
            {showLogo && (
                <LogoContainerStyled href="https://www.getplayback.com" title="Playback">
                    <Logo />
                </LogoContainerStyled>
            )}
            <MessageStyled
                onClick={onClick}
                mini={mini}
                $loading={loadingStates.includes(state) || loading}
            >
                {copy[state] || children}
            </MessageStyled>
        </RoomStateStyled>
    );
};

const Loading = (color: string) => keyframes`
    0% {
        text-decoration-color: ${transparentize(1, color)};
    }
    50% {
        text-decoration-color: ${color};
    }
    100% {
        text-decoration-color: ${transparentize(1, color)};
    }
`;

const MessageStyled = styled.div<{ mini: boolean; $loading: boolean }>`
    font-size: ${({ mini }) => rem(mini ? 16 : 24)};
    color: ${({ theme }) => theme.palette.White};
    text-align: center;
    margin: ${rem(20)};
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: underline;
    text-decoration-style: dashed;
    text-decoration-thickness: ${rem(2)};
    text-underline-offset: ${rem(3)};
    text-decoration-color: ${({ theme }) => theme.palette.MedGrey4};

    ${({ $loading, theme }) =>
        $loading &&
        css`
            animation: ${Loading(theme.palette.MedGrey4)} 1.5s ease-in-out infinite;
        `}

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        margin: ${rem(40)};
    }
`;

const LogoContainerStyled = styled.a`
    height: ${rem(21)};
    width: ${rem(226)};
    position: fixed;
    top: ${rem(24)};
    left: 50%;
    transform: translateX(-50%);

    svg {
        height: 100%;
        width: 100%;
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        top: ${rem(30)};
    }
`;

const RoomStateStyled = styled.div`
    max-width: ${rem(600)};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    box-sizing: border-box;
`;

export default RoomState;
