import { AppComponent } from 'typescript/typings';
import RoomState from 'packages/common/base/components/RoomState';
import { RoomStates } from 'packages/common/base/types';
import styled from 'styled-components';
import { rem } from 'polished';
import SEO from 'components/SEO';

interface PageError {
    code: 404 | 500;
}

const GIFS = {
    [404]: 'https://giphy.com/embed/b1YtwNsVRgyJ5IbvM3',
    [500]: 'https://giphy.com/embed/WUrgA8xOSUJj6JZoJC',
};

const PageError: AppComponent<PageError> = ({ code }) => {
    return (
        <PageErrorStyled>
            <SEO title={code === 404 ? 'Page not found' : 'Something went wrong'} />
            <RoomState state={code === 500 ? RoomStates.Error : undefined} logo>
                {code === 404 ? 'Hmm... we can’t find that page 🤔' : undefined}
            </RoomState>
            <GifStyled
                src={GIFS[code]}
                frameBorder="0"
                className="giphy-embed"
                allowFullScreen={false}
            />
        </PageErrorStyled>
    );
};

const GifStyled = styled.iframe`
    height: ${rem(280)};
    width: ${rem(280)};
    border-radius: ${rem(12)};
    pointer-events: none;
`;

const PageErrorStyled = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${rem(20)};
    box-sizing: border-box;
`;

export default PageError;
